import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Form, Alert } from 'reactstrap';
import { toast } from 'react-toastify';
import { Button, FormControl, CircularProgress, Select, InputLabel, MenuItem, FormHelperText } from '@material-ui/core';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import uploadService from '../../services/uploadService';

const initialState = {
  dataType: '',
  file: '',
};

const dataTypes = [
  {
    id: 'founders',
    name: 'Founder',
  },
  {
    id: 'funding-rounds',
    name: 'Funding Rounds',
  },
  {
    id: 'investors',
    name: 'Investors',
  },
  {
    id: 'companies',
    name: 'Startups',
  },
];

function MultiAdd(props) {
  const [error, setError] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [userInput, setUserInput] = useState(initialState);

  const inputRef = useRef(null);

  function handleChange(event) {
    console.log(event);
    const { name, value, type, checked } = event.target;
    const valueToUpdate = type === 'checkbox' ? checked : value;
    setUserInput((prevValue) => ({ ...prevValue, [name]: valueToUpdate }));
  }

  // handle submit
  async function handleSubmit(event) {
    event.preventDefault();
    setApiLoading(true);
    setError({});

    // const imagefile = document.querySelector('#file').files[0];

    // var imagefile = document.querySelector('#file');

    const formData = new FormData();
    const imagefile = inputRef.current.files[0];
    formData.append('file', imagefile);

    // setUserInput((prevValue) => ({ ...prevValue, file: imagefile }));
    // // formData.append(userInput)
    // console.log({ imagefile });
    try {
      const response = await uploadService.uploadDocument(userInput.dataType, formData);
      setUserInput(initialState);
      toast.success(response.message);
    } catch (err) {
      err.message && toast.error(err.message || 'error uploading data');
      setError({ ...err, errorType: userInput.dataType });
    }
    setApiLoading(false);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs mainSection="Upload" subSection="Bulk Upload" />
          {error?.message && <Alert color="danger">{error.message}</Alert>}
          <div>
            {error?.errors && Object.keys(error?.errors)?.length > 0 && (
              <Alert color="danger" style={{ marginTop: '13px' }}>
                <ul>
                  {Object.entries(error.errors).map(([key, value]) => (
                    <li key={key}>{value}</li>
                  ))}
                </ul>
              </Alert>
            )}
          </div>
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md="6">
                          {/* <input type="file" style={{ display: 'none' }} /> */}
                          <input
                            ref={inputRef}
                            type="file"
                            id="file"
                            // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            required
                            onChange={handleChange}
                          />
                          {/* <div className="form-group">
                            <Button variant="contained" component="label">
                              Upload File
                            </Button>
                          </div> */}
                        </Col>
                        <Col md="3">
                          <FormControl variant="outlined" fullWidth error={!!error?.errors?.companyId}>
                            <InputLabel id="dataType">Data Input</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={userInput.dataType}
                              name="dataType"
                              onChange={handleChange}
                              label="Data Input"
                              required
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>

                              {dataTypes.length > 0 &&
                                dataTypes.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                            </Select>
                            {error?.errors?.founders && <FormHelperText>{error?.errors?.companyId}</FormHelperText>}
                          </FormControl>
                        </Col>
                      </Row>
                      <div className="mt-3">
                        <Col md="4">
                          <Button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={apiLoading}
                          >
                            Upload
                            {apiLoading && <CircularProgress size={30} />}
                          </Button>
                        </Col>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(MultiAdd);
