import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  CircularProgress,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { Row, Col, Form } from 'reactstrap';
// import throttle from 'lodash/throttle';

import PhotoUpload from '../Common/PhotoUpload';
import Switch from '../Common/Switch';
import { formatDate } from '../../helpers/dateHelper';
import companyService from '../../services/companyService';
import defaultAvatar from '../../assets/images/avatar.png';

function FounderForm(props) {
  const [companyOptions, setCompanyOptions] = React.useState([]);
  // const [query, setQuery] = React.useState(' ');

  const { onSubmit, inputValues, onImageUrlChange, error, onImageError, onChange, apiLoading, isEditMode } = props;
  const {
    email,
    firstName,
    lastName,
    imageUrl,
    companyId,
    previousCompanies,
    birthDate,
    isVerified,
    websiteURL,
    twitterURL,
    instagramURL,
    linkedInURL,
    mediumURL,
    substackURL,
  } = inputValues;

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    onChange({ name, value, type, checked });
  };

  const updateImageUrl = (url) => {
    onImageUrlChange(url);
  };

  const setImageInputError = (error) => {
    onImageError(error);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  // const getCompanies = async (query) => {
  //   const response = await companyService.getCompanies({ query, , });
  //   const { companies } = response.data;
  //   setCompanyOptions(companies);
  // };
  // useEffect(() => {
  //   getCompanies(query);
  // }, [query]);

  const getCompanies = async (query) => {
    const response = await companyService.getCompanies({ limit: 50 });
    const { companies } = response.data;
    setCompanyOptions(companies);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  // useEffect(() => {
  //   let active = true;
  //   console.log('event fired');
  //   console.log(query);
  //   // if (!companyLoading) {
  //   //   return undefined;
  //   // }
  //   (async () => {
  //     // const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');
  //     // await sleep(1e3); // For demo purposes.
  //     // const countries = await response.json();
  //     const response = (await companyService.getCompanies({ query })).data;
  //     const { companies } = response;
  //     console.log({ companies });
  //     if (active) {
  //       setCompanyOptions(companies);
  //       // setCompanyOptions(companies.map((company) => company.name));
  //       // setCompanyOptions(Object.keys(countries).map((key) => countries[key].item[0]));
  //     }
  //   })();
  //   return () => {
  //     active = false;
  //   };
  // }, [query]);

  // useEffect(() => {
  //   if (!companyOpen) {
  //     setCompanyOptions([]);
  //   }
  // }, [companyOpen]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="6">
          <div className="form-group">
            <PhotoUpload
              uploadType="founder"
              imageUrl={imageUrl}
              defaultAvatar={defaultAvatar}
              name={`${firstName}-${lastName}`}
              updateImageUrl={updateImageUrl}
              onError={setImageInputError}
              errorMessage={error?.errors?.imageUrl}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="firstName"
              name="firstName"
              type="text"
              label="First Name"
              value={firstName}
              helperText={error?.errors?.firstName}
              error={!!error?.errors?.firstName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="lastName"
              name="lastName"
              type="text"
              label="Last Name"
              value={lastName}
              helperText={error?.errors?.lastName}
              error={!!error?.errors?.lastName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="email"
              name="email"
              type="email"
              label="Email"
              value={email}
              helperText={error?.errors?.email}
              error={!!error?.errors?.email}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="birthDate"
              name="birthDate"
              type="date"
              label="Date of Birth"
              value={birthDate ? formatDate(new Date(birthDate), 'yyyy-MM-dd') : birthDate}
              helperText={error?.errors?.firstName}
              error={!!error?.errors?.firstName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <div className="form-group">
            <FormControl variant="outlined" fullWidth error={!!error?.errors?.companyId}>
              <InputLabel id="companyId">Company</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={companyId}
                name="companyId"
                onChange={handleChange}
                label="Company"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {companyOptions &&
                  companyOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
              {error?.errors?.founders && <FormHelperText>{error?.errors?.companyId}</FormHelperText>}
            </FormControl>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <FormControl variant="outlined" fullWidth error={error?.errors?.previousCompanies}>
              <InputLabel io="previousCompanies">Previous Companies</InputLabel>
              <Select
                label="previousCompanies"
                id="previousCompanies"
                multiple
                name="previousCompanies"
                value={previousCompanies}
                onChange={handleChange}

                // input={<Input id="select-multiple-chip" />}
                // renderValue={(selected) => (
                //   <div>
                //     {selected.map((value) => (
                //       <Chip key={value} label={value} />
                //     ))}
                //   </div>
                // )}
                // MenuProps={MenuProps}
              >
                <MenuItem value={[]}>
                  <em>None</em>
                </MenuItem>
                {companyOptions?.length > 0 &&
                  companyOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
              {error?.errors?.previousCompanies && <FormHelperText>{error?.errors?.previousCompanies}</FormHelperText>}
            </FormControl>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="websiteURL"
              name="websiteURL"
              type="url"
              label="Website"
              placeholder="https://johndoe.com"
              value={websiteURL}
              helperText={error?.errors?.websiteURL}
              error={!!error?.errors?.websiteURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="linkedInURL"
              name="linkedInURL"
              type="url"
              label="LinkedIn"
              value={linkedInURL}
              placeholder="https://linkedin.com/jdoe"
              helperText={error?.errors?.linkedInURL}
              error={!!error?.errors?.linkedInURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="twitterURL"
              name="twitterURL"
              type="url"
              label="Twitter account"
              placeholder="https://twitter.com/jdoe"
              value={twitterURL}
              helperText={error?.errors?.twitterURL}
              error={!!error?.errors?.twitterURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="instagramURL"
              name="instagramURL"
              type="url"
              label="Instagram page"
              value={instagramURL}
              placeholder="https://instagram.com/jdoe"
              helperText={error?.errors?.instagramURL}
              error={!!error?.errors?.instagramURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="mediumURL"
              name="mediumURL"
              type="url"
              label="medium page"
              placeholder="https://medium.com/jdoe"
              value={mediumURL}
              helperText={error?.errors?.mediumURL}
              error={!!error?.errors?.mediumURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <TextField
              id="substackURL"
              name="substackURL"
              type="url"
              label="Substack"
              placeholder="https://jdoe.substack.com"
              value={substackURL}
              helperText={error?.errors?.substackURL}
              error={!!error?.errors?.substackURL}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </div>
        </Col>
      </Row>
      <FormControlLabel
        control={
          <Switch
            checked={isVerified}
            onChange={handleChange}
            name="isVerified"
            // inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        }
        label={isVerified ? 'verified account' : 'regular account'}
      />

      <div className="mt-3">
        <Col md="4">
          <Button
            className="btn btn-primary btn-block waves-effect waves-light"
            type="submit"
            variant="outlined"
            color="primary"
            disabled={apiLoading || !imageUrl || !firstName || !lastName}
          >
            {isEditMode ? 'Edit Founder' : 'Add Founder'}
            {apiLoading && <CircularProgress size={30} />}
          </Button>
        </Col>
      </div>
    </Form>
  );
}

export default FounderForm;
